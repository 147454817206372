import React, { useState } from "react";
// import { Link } from "gatsby";
// import { Container, Row, Col, Dropdown } from "react-bootstrap";
import "./MortgageRepayments.scss";
import RentalYieldForm from "../MortgageForm/RentalYieldForm";
// import RentalCalc from "../calculators/rentalyieldcalc/index"

const RentalYield = (props) => {
  const [years, setYears] = useState("25");
  const yearSelect = (e) => {
    setYears(e);
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <div className="mortgage-repayments-calc">
	    	<RentalYieldForm price={props.price}/>
            {/* <RentalCalc/> */}
      </div>
    </React.Fragment>
  );
};

export default RentalYield;
