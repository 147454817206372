import React, { useState } from "react";
import "./MortgageRepayments.scss";
import Mortgage from "../MortgageForm/MortgageForm";

const MortgageRepayments = (props) => {
  const [years, setYears] = useState("25");
  const yearSelect = (e) => {
    setYears(e);
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <div className="mortgage-repayments-calc">
	    	<Mortgage price={props.price}/>
      </div>
    </React.Fragment>
  );
};

export default MortgageRepayments;
