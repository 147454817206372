import React from "react"
import { Link } from "gatsby"
import { useMedia } from "react-use"
import "./assets/styles/_index.scss"
import sbLogo from "../../images/icons/sb-logo.svg"

const PropertyDetailsFooter = (props) => {
  const isMobile = useMedia("(max-width: 768px)", false)
  const currentYear = new Date().getFullYear()

  return (
    <section className="property-details-footer">
      <div className="book-valuation">Book a Valuation</div>
      <h2 className="property-footer-heading">
        List your property with OM Home
      </h2>
      <div className="property-footer-btn">
        <Link to="#" className="btn btn-white w-100">
          Find our more
        </Link>
      </div>
      <ul className="list-unstyled property-footer-list">
        <li>
          <Link to="#">About om home</Link>
        </li>
        <li>
          <Link to="#">Buy property</Link>
        </li>
        <li>
          <Link to="#">Sell property</Link>
        </li>
        <li>
          <Link to="#">New properties</Link>
        </li>
        <li>
          <Link to="#">Communities</Link>
        </li>
        <li>
          <Link to="#">Reviews</Link>
        </li>
      </ul>
      <div className="property-footer-divider"></div>
      <div className="property-footer-end">
        <div className="terms-section">
          <p className="copyright">© {currentYear} om home</p>
          <div className="links">
            <a href="/privacy/">Privacy</a>
            {isMobile && " / "}
            <a href="/terms-and-conditions/">Terms and Conditions</a>
          </div>
          <div className="site-by">
            Site by:{" "}
            <a href="https://starberry.tv/">
              <img src={sbLogo} alt="Starberry Logo" />
            </a>
          </div>
        </div>
        <div className="site-by show_large">
            Site by:{" "}
            <a href="https://starberry.tv/">
              <img src={sbLogo} alt="Starberry Logo" />
            </a>
          </div>
      </div>
    </section>
  )
}

export default PropertyDetailsFooter
