import React, { useState } from 'react';
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "gatsby";
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import './assets/styles/_index.scss';
import Img1 from "../../images/similar-properties-img-1.png";
import Img2 from "../../images/similar-properties-img-2.png";

const SimilarProperties = () => {

    // Slider settings
    let settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 800,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
        {
            breakpoint: 1200,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            arrows: true,
            dots: false,
            },
        },
        {
            breakpoint: 992,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            arrows: true,
            dots: false,
            },
        },
        {
            breakpoint: 767,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            arrows: true,
            dots: false,
            },
        },
        ],
    }
    // Slider settings

    // Property images lightbox
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const openPropertyImage = (e, ind) => {
        e.preventDefault()
        setPhotoIndex(ind)
        setIsOpen(true)
    }
    // Property images lightbox

    // Property details images lightbox
    // const propertyImages = props?.propertyData?.images

    const propertyImages = [
        Img1,
        Img2
    ];

    // var propertyLightImages =
    //     propertyImages && propertyImages.map(img => img.url)
    // Property details images lightbox

    return (
        <section className="similar-properties-slider-wrapper">
            <Row>
                <Col>
                    <div className="similar-properties-heading">Similar Properties</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Slider className="similar-properties-slider" {...settings}>
                        <div className="similar-properties-slide">
                            <div className="similar-properties-img-zoom">
                                <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)}>
                                    <img src={Img1} className="img-fluid" />
                                </a>
                            </div>
                            <div className="similar-properties-details">
                                <div className="price-details">AED 1,000,000 <span>1,200,000</span></div>
                                <Link to="#" className="display-address">Al Andalus, Jumeirah Golf Estates</Link>
                                <ul className="list-inline property-features">
                                    <li className="list-inline-item">3 Bed</li>
                                    <li className="list-inline-item">1 Bath</li>
                                    <li className="list-inline-item">1,256 sqft</li>
                                </ul>
                            </div>
                        </div>
                        <div className="similar-properties-slide">
                            <div className="similar-properties-img-zoom">
                                <Link to="#">
                                    <img src={Img2} className="img-fluid" />
                                </Link>
                            </div>
                            <div className="similar-properties-details">
                                <div className="price-details">AED 1,000,000 <span>1,200,000</span></div>
                                <Link to="#" className="display-address">Al Andalus, Jumeirah Golf Estates</Link>
                                <ul className="list-inline property-features">
                                    <li className="list-inline-item">3 Bed</li>
                                    <li className="list-inline-item">1 Bath</li>
                                    <li className="list-inline-item">1,256 sqft</li>
                                </ul>
                            </div>
                        </div>
                        <div className="similar-properties-slide">
                            <div className="similar-properties-img-zoom">
                                <Link to="#">
                                    <img src={Img1} className="img-fluid" />
                                </Link>
                            </div>
                            <div className="similar-properties-details">
                                <div className="price-details">AED 1,000,000 <span>1,200,000</span></div>
                                <Link to="#" className="display-address">Al Andalus, Jumeirah Golf Estates</Link>
                                <ul className="list-inline property-features">
                                    <li className="list-inline-item">Apartment</li>
                                    <li className="list-inline-item">3 Bed</li>
                                    <li className="list-inline-item">1 Bath</li>
                                    <li className="list-inline-item">1,256 sqft</li>
                                </ul>
                            </div>
                        </div>
                    </Slider>
                </Col>
            </Row>
            
            {isOpen && (
                <Lightbox
                    mainSrc={propertyImages[photoIndex]}
                    nextSrc={
                        propertyImages[
                        (photoIndex + 1) % propertyImages.length
                        ]
                    }
                    prevSrc={
                        propertyImages[
                        (photoIndex + propertyImages.length - 1) %
                        propertyImages.length
                        ]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                        (photoIndex + propertyImages.length - 1) %
                        propertyImages.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyImages.length)
                    }
                />
            )}
        </section>
    )
}

export default SimilarProperties